import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Layout from "../components/uses/layout.module.scss";
import Card from "../components/uses/card.module.scss";
import AppCard from "../components/uses/application";
import ProductCard from "../components/uses/product";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="“Uses” | May 21 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-21.png" url="https://cssgrid31.brett.cool" pathname="/may-21" mdxType="Metatags" />
    <div className={Layout.container}>
  <div className={Layout.hero}>
    <div className={Layout.sidebar}>
      <h1>Just a few of my favourite things</h1>
      <ol>
        <li><a href="#edc">Everyday Carry</a></li>
        <li><a href="#macos">macOS Applications</a></li>
        <li><a href="#ios">iOS Applications</a></li>
      </ol>
    </div>
  </div>
  <div className={Layout.body}>
    <h2 id="edc">Everyday Carry</h2>
    <ProductCard image="iphone" title="iPhone XS" description="Silver 256gb" link="https://www.apple.com/au/iphone-xs/" mdxType="ProductCard" />
    <ProductCard image="macbookpro" title="13” MacBook Pro" description="13-inch, Late 2016, Four Thunderbolt 3 Ports" link="https://www.apple.com/au/macbook-pro/" mdxType="ProductCard" />
    <h2 id="macos">macOS Applications</h2>
    <AppCard icon="sketch" title="Sketch" description="Design tool of choice. Fast to work in, with great export workflows." link="https://sketchapp.com" mdxType="AppCard" />
    <AppCard icon="vscode" title="VSCode" description="Feature rich text editor, with an impressive cadence of releases." link="https://code.visualstudio.com" mdxType="AppCard" />
    <AppCard icon="hyper" title="Hyper" description="A nice minimal, extensible Terminal client. I like it mainly ‘cause it looks nice." link="https://hyper.is" mdxType="AppCard" />
    <AppCard icon="screenflick" title="Screenflick" description="Very high quality screen recording software, useful for recording hi-dpi, high framerate video." link="https://www.araelium.com/screenflick-mac-screen-recorder" mdxType="AppCard" />
    <AppCard icon="things" title="Things 3" description="A clean and simple task manager for all my life admin. Syncs with iPhone and Apple watch." link="https://culturedcode.com/things/" mdxType="AppCard" />
    <AppCard icon="iawriter" title="iA Writer" description="Writing app of choice. Beautiful and simple, with great Markdown support and exporting." link="https://ia.net/writer" mdxType="AppCard" />
    <AppCard icon="iconjar" title="IconJar" description="A handy application for organising, finding, using, and exporting icons and icon sets." link="https://geticonjar.com/" mdxType="AppCard" />
    <AppCard icon="1password" title="1Password" description="Password manager. Syncs with iOS, supports Touch ID on Mac, and Touch + Face ID on iOS." link="https://1password.com/" mdxType="AppCard" />
    <h2 id="ios">iOS Applications</h2>
    <AppCard icon="darkroom" title="Darkroom" description="A robust photo editor for iPhone and iPad. Great UI, and very powerful." link="https://itunes.apple.com/au/app/darkroom-photo-editor/id953286746" mdxType="AppCard" />
    <AppCard icon="timepage" title="Timepage" description="A rather unique take on calendar apps by Moleskin, with a bold, beautiful design." link="https://itunes.apple.com/au/app/timepage/id989178902" mdxType="AppCard" />
    <AppCard icon="filtru" title="Filtru" description="Coffee timer forwith brew guides, and custom recipes." link="https://itunes.apple.com/us/app/filtru-coffee/id1150921819" mdxType="AppCard" />
    <AppCard icon="gyroscope" title="Gyroscope" description="Tracking all the details of my life and activity. Offers fascinating insights." link="https://itunes.apple.com/app/apple-store/id1104085053" mdxType="AppCard" />
    <AppCard icon="iawriter-ios" title="iA Writer" description="Note taking application on iOS. Syncs with desktop via iCloud." link="https://itunes.apple.com/au/app/ia-writer/id775737172" mdxType="AppCard" />
    <AppCard icon="overcast" title="Overcast" description="Podcast client from indie developer Marco Arment. Great smart speed adjustments." link="https://itunes.apple.com/au/app/overcast/id888422857" mdxType="AppCard" />
    <AppCard icon="things-ios" title="Things" description="Task manager for life admin on iPhone, syncs wth desktop, and integrates with Siri Shortcuts." link="https://itunes.apple.com/au/app/ia-writer/id775737172" mdxType="AppCard" />
    <AppCard icon="threes" title="Threes!" description="The one iOS game I’ve played consistently over the years." link="https://itunes.apple.com/au/app/threes/id779157948" mdxType="AppCard" />
  </div>
    </div>
    <Footer date={21} prev={true} next={true} mdxType="Footer">
      <p>{`Inspired by `}<a parentName="p" {...{
          "href": "https://wesbos.com/uses/"
        }}>{`Wes Bos’s “Uses”`}</a>{` page, here’s a list of products and applications I use.`}</p>
      <p>{`I’ll probably add more to this list later—I kind of ran out of time, plus I’m at the pub, and we’re got food to eat!`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      